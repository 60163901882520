import * as React from "react"
import SchedulerService from "../../../../../components/Drive/Scheduler/service/schedulerService"
import Seo from "../../../../../components/seo"
import PrivateRoute from "../../../../../components/privateRoute"
import ReduxWrapper from "../../../../../redux/reduxWrapper"

const SchedulerServicePage = props => {
  return (
    <React.Fragment>
      <Seo title="Scheduler Service" />
      <PrivateRoute
        path="/"
        component={SchedulerService}
        schedulerId={props.fileId}
        sceId={props.sceId}
        location={props.location}
      />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<SchedulerServicePage {...props} />} />
export default WrappedPage
