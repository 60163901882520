import React, { useEffect } from "react"

const InitialRow = ({ states, setStates }) => {
  const updateData = (e, fieldName, value) => {
    if (fieldName === undefined) {
      fieldName = e.target.name
      value = e.target.value
    }

    setStates({
      ...states,
      dhAuto: { ...states.dhAuto, [fieldName]: value },
    })
  }

  useEffect(() => {
    if (states.dhAuto.automation === "accessTracking") {
      updateData(null, "direction", "Decrease")
    }
  }, [states.dhAuto.automation])

  return (
    <div className="blueOutlineBox initial_data_source ">
      <div className="row">
        <div className="col-lg-12">
          <select
            className="form-select inputFeedType"
            name="automation"
            onChange={updateData}
            value={states.dhAuto?.automation}
          >
            <option value="trackUseAccess" disabled="">
              {" "}
              == TRACK USE / ACCESS =={" "}
            </option>
            <option value="accessGranted">Access Granted</option>
            <option value="accessDenied">Access Denied</option>
            <option value="category_msg" disabled="">
              {" "}
              == NOTIFY =={" "}
            </option>
            <option value="accessTracking">Use &amp; Access Tracking</option>
            <option value="automation_2">
              Email Me at{" "}
              {states.primaryEmails !== undefined ? states.primaryEmails[0].value : "My primary email"}
            </option>
            <option value="emailTo">Email To...</option>
          </select>
        </div>

        {states.dhAuto?.automation === "emailTo" && (
          <div className="col-lg-12 automation_input mt-2 emailTo_cls">
            <input
              type="email"
              name="email_to"
              placeholder="Enter Email"
              value={states.dhAuto?.email_to ?? ""}
              onChange={updateData}
              className="form-control"
            />
            <div className="row row-cols-sm-auto g-2 mt-0 align-items-center">
              <div className="col-12">Email Format:</div>
              <div className="col-12">
                <input
                  type="radio"
                  name="email_format"
                  value="HTMLFullDetails"
                  className="css-checkbox"
                  defaultChecked={states.dhAuto?.email_format === "HTMLFullDetails"}
                  onChange={updateData}
                  id="emailFormatHTMLFullDetailsInitial"
                />
                <label htmlFor="emailFormatHTMLFullDetailsInitial" className="css-radio-label">
                  HTML Full Details
                </label>
              </div>
              <div className="col-12">
                <input
                  type="radio"
                  name="email_format"
                  value="Plain"
                  className="css-checkbox"
                  defaultChecked={states.dhAuto?.email_format === "Plain"}
                  onChange={updateData}
                  id="emailFormatPlainInitial"
                />
                <label htmlFor="emailFormatPlainInitial" className="css-radio-label">
                  Plain Text
                </label>
              </div>
              <div className="col-12">
                <input
                  type="radio"
                  name="email_format"
                  value="Template"
                  className="css-checkbox"
                  defaultChecked={states.dhAuto?.email_format === "Template"}
                  onChange={updateData}
                  id="emailFormatTemplateInitial"
                />
                <label htmlFor="emailFormatTemplateInitial" className="css-radio-label">
                  Template
                </label>
              </div>

              {states.dhAuto?.email_format === "Template" && (
                <div className="col-12 emailFormatTemplateOpt emailFormatTemplateBox">
                  <select name="template_name" className="form-select">
                    <option>== List of Template ==</option>
                  </select>
                </div>
              )}

              <div className="col-12">
                <input
                  type="radio"
                  name="email_format"
                  defaultChecked={states.dhAuto?.email_format === "Digest"}
                  onChange={updateData}
                  value="Digest"
                  className="css-checkbox"
                  id="emailFormatDigestInitial"
                />
                <label htmlFor="emailFormatDigestInitial" className="css-radio-label">
                  Digest Option
                </label>
              </div>

              {states.dhAuto?.emailFormat === "Digest" && (
                <div className="col-12 emailFormatTemplateOpt emailFormatDigestBox">
                  <select
                    name="digest_opt"
                    className="form-select"
                    onChange={updateData}
                    value={states.dhAuto?.digest_opt ?? ""}
                  >
                    <option>== Select ==</option>
                    <option value="Day">Day</option> <option value="Week">Week</option>
                    <option value="Month">Month</option>
                  </select>
                </div>
              )}
            </div>
          </div>
        )}

        {states.dhAuto?.automation === "accessTracking" && (
          <div className="col-lg-12 automation_input mt-2 accessTracking_cls">
            <div className="row row-cols-sm-auto g-2 align-items-center">
              <div className="col-12">
                <select
                  className="form-select"
                  name="direction"
                  value={states.dhAuto?.direction}
                  onChange={updateData}
                >
                  <option value="Decrease">Decrease</option>
                  <option value="Increase">Increase</option>
                </select>
              </div>
              <div className="col-12">
                <select className="form-select">
                  <option value="uInventory">Inventory / User's Inventory</option>
                </select>
              </div>
              <div className="col-12">for item</div>
              <div className="col-12">
                <select className="form-select">
                  <option>BEANCOUNTERS SHOW HERE</option>
                </select>
              </div>
              <div className="col-12">by</div>
              <div className="col-12">
                <input
                  type="number"
                  name="for_item_cnt"
                  className="form-control"
                  placeholder="1"
                  onChange={updateData}
                  value={states.dhAuto?.for_item_cnt ?? 1}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default InitialRow
