import React, { useState, useEffect } from "react"
import Layout from "../../../Layout/layout"
import { Link } from "gatsby"
import UxPanel from "./uxPanel"
import DhAutoPanel from "./dhAuto/dhAutoPanel"
import MoreOptions from "./moreOptions"
import Detail from "./detail"
import AxiosInstance from "../../../../services/axiosInstance"
import "../scheduler.css"
import { getInvoices, getPrimaryEmail, getSpreadsheets } from "../../../Global/apiHelpers"
import { getSkus } from "../../../Global/apiHelpers"
import { getSequences } from "../../../Global/apiHelpers"
import { getLanguages } from "../../../Global/apiHelpers"
import Installation from "../installation/installation"
import { getUserName } from "../../Doc/Service/docHelper"
import { getUserCurrency } from "../../../../services/globalHelpers"

const SchedulerService = ({ sceId, schedulerId }) => {
  const currencyData = getUserCurrency()

  const [states, setStates] = useState({
    isDataLoaded: false,
    schedulerFileUrl: schedulerId,
    fileId: schedulerId,
    sceId,
    isCustomizedUrl: false,
    timeRequiredJson: {
      timeFlexibility: "Fixed",
      timeReq: 30,
      timeFreq: "minutes",
    },
    visibility: "public",
    locationJson: {
      locationOpt: "office",
    },
    ticketedJson: {
      ticketed: "no",
    },
    membershipJson: [],
    uxFlow: {
      payable: "free",
      paperworkReq: "notRequired",
      additional_options: [
        {
          set_option: "getAnEmail",
          subject: "Thanks [swift_name FirstCap]! Here's a copy of your doc(s)",
        },
      ],
    },
    dhAuto: {
      automation_option: [],
    },
    moreOptions: {},
    accountCurrencySymbol: currencyData.symbol ?? "$",
  })

  const loadData = async () => {
    try {
      const response = await AxiosInstance.get(`/drive/service/scheduler/${sceId}/detail`)

      if (response.status === 200) {
        const service = response.data.service
        const timeRequiredJson =
          Object.keys(service.timeRequiredJson).length > 0
            ? service.timeRequiredJson
            : states.timeRequiredJson
        const locationJson =
          Object.keys(service.locationJson).length > 0 ? service.locationJson : states.locationJson
        const ticketedJson =
          Object.keys(service.ticketedJson).length > 0 ? service.ticketedJson : states.ticketedJson
        const uxFlow =
          Object.keys(service.driveForm?.ueFlow).length > 0 ? service.driveForm?.ueFlow : states.uxFlow
        const dhAuto =
          Object.keys(service.driveForm?.dhAutomation).length > 0
            ? service.driveForm?.dhAutomation
            : states.dhAuto
        const drive = service.drive
        const username = getUserName()

        setStates({
          ...states,
          isDataLoaded: true,
          ...response.data.service,
          timeRequiredJson,
          locationJson,
          ticketedJson,
          primaryEmails: await getPrimaryEmail(),
          skus: await getSkus(),
          sequences: await getSequences(),
          languages: await getLanguages(),
          invoices: await getInvoices(),
          spreadsheets: await getSpreadsheets(),
          uxFlow,
          dhAuto,
          drive,
          isCustomizedUrl: drive.mappedUrl?.mappedUrl !== undefined,
          previewUrl:
            drive.mappedUrl?.mappedUrl ??
            `${process.env.GATSBY_PREVIEW_URL}/${username}/${
              drive.slug ?? `scheduler/${states.schedulerFileUrl}/?service=${states.sceId}`
            }`,
          moreOptions: service.driveForm?.moreOptions,
        })

        return
      }
      console.log("Unexpected response status: ", response.status)
    } catch (error) {
      console.error("Error when retrieving service details: ", error)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const saveData = async () => {
    if (states.name.length === 0 && states.isDataLoaded) {
      console.error("Name cannot be empty!!")

      return
    }

    const {
      name,
      description,
      timeRequiredJson,
      visibility,
      locationJson,
      ticketedJson,
      uxFlow,
      dhAuto,
      moreOptions,
    } = states
    const postData = {
      name,
      description,
      timeRequiredJson,
      visibility,
      locationJson,
      ticketedJson,
      ueFlow: uxFlow,
      dhAutomation: dhAuto,
      moreOptions,
    }
    try {
      const response = await AxiosInstance.post(
        `/drive/service/scheduler/${states.drive.fileURL}/update`,
        postData
      )
      if (response.status === 202) {
        console.log("Service data saved successfully...")

        return
      }

      console.log("Unexpected response status: ", response.status)
    } catch (error) {
      console.error("Error when saving service: ", error)
    }
  }

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <div className="row g-0">
              <div className="col-lg-12 mb-3">
                <div className="pageTitle_Editor">
                  <Link to={`/drive/scheduler/${schedulerId}`} className="goBack">
                    <i className="bi bi-arrow-90deg-left"></i>
                  </Link>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Untitled Service"
                    value={states.name ?? ""}
                    onChange={e => setStates({ ...states, name: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-lg-12 mb-3">
                <div className="row g-0">
                  <Detail states={states} setStates={setStates} />

                  <UxPanel states={states} setStates={setStates} />

                  <DhAutoPanel states={states} setStates={setStates} />

                  <Installation states={states} setStates={setStates} />

                  <MoreOptions states={states} setStates={setStates} />

                  <div className="col-lg-12 footer-action mb-3 text-center">
                    <div className="footer-action-btns">
                      <button
                        className={`btnTogglePanel${states.showUxPanel ? " active" : ""}`}
                        data-panel="for_them_panel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="FOR THEM: User Experience Flow"
                        aria-label="FOR THEM: User Experience Flow"
                        aria-describedby="tooltip447514"
                        onClick={e => setStates({ ...states, showUxPanel: !states.showUxPanel })}
                      >
                        <i className="bi bi-box-arrow-right"></i>
                      </button>{" "}
                      <button
                        className={`btnTogglePanel${states.showDhAutoPanel ? " active" : ""}`}
                        data-panel="for_you_panel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="FOR YOU: Notification"
                        aria-label="FOR YOU: Notification"
                        onClick={e => setStates({ ...states, showDhAutoPanel: !states.showDhAutoPanel })}
                      >
                        <i className="bi bi-gear-fill"></i>
                      </button>{" "}
                      <button
                        className={`btnTogglePanel${states.showInstallationPanel ? " active" : ""}`}
                        data-panel="installation_panel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Using this Scheduler"
                        aria-label="Using this Scheduler"
                        onClick={e =>
                          setStates({ ...states, showInstallationPanel: !states.showInstallationPanel })
                        }
                      >
                        <i className="bi bi-megaphone-fill"></i>
                      </button>{" "}
                      <button
                        className={`btnTogglePanel${states.showMoreOptionsPanel ? " active" : ""}`}
                        data-panel="more_panel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="More Options"
                        aria-label="More Options"
                        onClick={e =>
                          setStates({ ...states, showMoreOptionsPanel: !states.showMoreOptionsPanel })
                        }
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-12 text-center footer-action">
                    <button type="button" className="btn btn-primary btn-lg" onClick={saveData}>
                      <i className="bi bi-check2"></i> Save
                    </button>{" "}
                    <button type="button" className="btn btn-secondary btn-lg">
                      <i className="bi bi-box-arrow-up-right"></i> Preview
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-default btn-lg btnOpenChatDock"
                      data-bs-toggle="tooltip"
                      title=""
                      data-bs-original-title="History"
                      aria-label="History"
                    >
                      <i className="bi bi-arrow-counterclockwise"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SchedulerService
