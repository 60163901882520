import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import EmailOption from "./uxFlow/emailOption"
import SmsOption from "./uxFlow/smsOption"
import CurrencyIcon from "../../../Tools/currencyIcon"

const UxPanel = ({ states, setStates }) => {
  const updateData = e => {
    setStates({ ...states, uxFlow: { ...states.uxFlow, [e.target.name]: e.target.value } })
  }

  const updateAdditionalOption = (e, index, fieldName, value) => {
    if (fieldName === undefined) {
      fieldName = e.target.name
      value = e.target.value
    }
    const additional_options = states.uxFlow.additional_options.map((option, i) => {
      if (i !== index) return option

      return { ...option, [fieldName]: value }
    })

    setStates({ ...states, uxFlow: { ...states.uxFlow, additional_options } })
  }

  const addOption = e => {
    setStates({
      ...states,
      uxFlow: {
        ...states.uxFlow,
        additional_options: [
          ...(states.uxFlow.additional_options ?? []),
          {
            set_option: "getAnEmail",
            subject: "Thanks [swift_name FirstCap]! Here's a copy of your doc(s)",
          },
        ],
      },
    })
  }

  const removeOption = (e, index) => {
    setStates({
      ...states,
      uxFlow: {
        ...states.uxFlow,
        additional_options: states.uxFlow.additional_options.filter((option, i) => i !== index),
      },
    })
  }

  return (
    <div className={`col-lg-12 mb-3${states.showUxPanel ? "" : " d-none"}`} id="for_them_panel">
      <div className="accordion" id="forthemPanelAccordion">
        <div className="accordion-item panel panel-pink">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#for_them_container"
              aria-expanded="true"
              aria-controls="for_them_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-box-arrow-right"></i>
              </span>
              <span className="panel-heading-title">FOR THEM: User Experience Flow</span>
            </div>
          </div>
          <div
            id="for_them_container"
            className="accordion-collapse collapse show"
            aria-labelledby="for_them_container"
          >
            <div className="accordion-body">
              <div className="forThemOptionContainer">
                <div className="pinkOutlineBox">
                  <div className="row row-cols-sm-auto g-2 align-items-center">
                    <div className="col-12">
                      <CurrencyIcon currencySymbol={states.accountCurrencySymbol} />
                      This meeting is
                    </div>
                    <div className="col-12">
                      <select
                        name="payable"
                        className="form-select"
                        onChange={updateData}
                        value={states.uxFlow?.payable ?? "free"}
                      >
                        <option value="free">Free</option>
                        <option value="paid upfront during Booking">Paid (Upfront during Booking)</option>
                        <option value="paid at appointment">Paid (At Appointment)</option>
                        <option value="authorize card only">Authorize Card Only</option>
                      </select>
                    </div>

                    {states.uxFlow?.payable !== "free" && (
                      <div className="col-12 paidMeetingOptRow">
                        <select
                          className="form-select"
                          name="selected_sku"
                          onChange={updateData}
                          value={states.uxFlow?.selected_sku}
                        >
                          <option>== List of Product ==</option>
                          {states.skus?.map((sku, index) => {
                            return (
                              <option value={sku.id} key={index}>
                                {sku.itemName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pinkOutlineBox">
                  <div className="row row-cols-sm-auto g-2 align-items-center">
                    <div className="col-12">
                      <i className="bi bi-pencil-square"></i> Paperwork is{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            Most common is a liability waiver, but could be an intake form, pre-meeting
                            questionnaire, etc. - choose any 1 doc or form
                          </Tooltip>
                        }
                      >
                        <span
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Most common is a liability waiver, but could be an intake form, pre-meeting questionnaire, etc. - choose any 1 doc or form"
                          aria-label="Most common is a liability waiver, but could be an intake form, pre-meeting questionnaire, etc. - choose any 1 doc or form"
                        >
                          <i className="bi bi-question-circle-fill"></i>
                        </span>
                      </OverlayTrigger>
                    </div>
                    <div className="col-12">
                      <select
                        name="paperwork_req"
                        className="form-select"
                        value={states.uxFlow?.paperwork_req ?? "notREquired"}
                        onChange={updateData}
                      >
                        <option value="notRequired">Not Required</option>
                        <option value="required">Required</option>
                      </select>
                    </div>

                    {states.uxFlow?.paperwork_req === "required" && (
                      <div className="col-12 paperworkReqRow">
                        <select className="form-select">
                          <option>== List of Docs ==</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>

                {states.uxFlow?.additional_options?.map((option, index) => {
                  return (
                    <div className="pinkOutlineBox" key={index}>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Remove</Tooltip>}>
                        <button
                          className="btnRemoveFTOption"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Remove"
                          aria-label="Remove"
                          onClick={e => removeOption(e, index)}
                        >
                          <i className="bi bi-x-lg"></i>
                        </button>
                      </OverlayTrigger>

                      <div className="row">
                        <div className="col-lg-8 col-md-10 mx-auto">
                          <div className="row row-cols-sm-auto g-2 align-items-center">
                            <div className="col-12">
                              <select
                                className="form-select invisibleSelect"
                                name="role"
                                value={option.role ?? ""}
                                onChange={e => updateAdditionalOption(e, index)}
                              >
                                <option>== ROLES ==</option>
                                <option>Borrower</option>
                              </select>
                            </div>
                            <div className="col-12">will...</div>
                            <div className="col-12">
                              <select
                                name="set_option"
                                className="form-select FT_automationOption"
                                value={option.set_option ?? "getAnEmail"}
                                onChange={e => updateAdditionalOption(e, index)}
                              >
                                <option disabled="disabled">== Select Option ==</option>
                                <option value="getAnEmail">Get an Email</option>
                                <option value="SMS">Get an SMS</option>
                                <option value="marketingSequence">Get added to a sequence</option>
                                <option value="removeFromMarketingSequence">
                                  Get removed from a sequence
                                </option>
                              </select>
                            </div>

                            {["marketingSequence", "removeFromMarketingSequence"].includes(
                              option.set_option
                            ) && (
                              <div className="col-12 mt-2 forThemOptionField FT_marketingSequence FT_removeFromMarketingSequence">
                                <select
                                  name="marketing_sequence"
                                  className="form-select invisibleSelect"
                                  value={option.marketingSequence ?? ""}
                                  onChange={e => updateAdditionalOption(e, index)}
                                >
                                  <option>== List of Sequences ==</option>
                                  {states.sequences?.map((sequence, index) => {
                                    return (
                                      <option value={sequence.id} key={index}>
                                        {sequence.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                        {option.set_option === "getAnEmail" && (
                          <EmailOption
                            states={states}
                            setStates={setStates}
                            index={index}
                            updateData={updateAdditionalOption}
                          />
                        )}

                        {option.set_option === "SMS" && (
                          <SmsOption
                            states={states}
                            setStates={setStates}
                            index={index}
                            updateData={updateAdditionalOption}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}

                <div className="text-center">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add Options to User Experience</Tooltip>}>
                    <button
                      className="btnAddNewGreenDashed btnAddNewFTOption"
                      data-bs-toggle="tooltip"
                      title=""
                      data-bs-original-title="Add Options to User Experience"
                      aria-label="Add Options to User Experience"
                      onClick={addOption}
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UxPanel
