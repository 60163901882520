import React, { useRef } from "react"

const SmsOption = ({ states, setStates, index, updateData }) => {
  const option = states.uxFlow.additional_options[index]
  const mainMessageRef = useRef(null)

  const injectVariable = e => {
    e.preventDefault()
    const start = mainMessageRef.current.selectionStart
    const end = mainMessageRef.current.selectionEnd
    const currentValue = mainMessageRef.current.value
    const newText = `${currentValue.slice(0, start)}[${e.target.innerText}]${currentValue.slice(end)}`
    const newPosition = start + `[${e.target.innerText}]`.length
    mainMessageRef.current.setSelectionRange(newPosition, newPosition)

    updateData(null, index, mainMessageRef.current.name, newText)
  }

  const doNothing = e => {
    e.preventDefault()
  }

  return (
    <div className="col-lg-8 col-md-10 mx-auto mt-3 forThemOptionField FT_SMS">
      <div className="colHeading">
        <strong>
          <i className="bi bi-envelope-fill"></i> Autoresponder
        </strong>
      </div>
      <div className="dh-block-body">
        <div className="mb-2">
          <div className="d-inline-block">
            <button
              className="btn btnAddNewGreenDashed"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Inject Recipient Variable i.e. Firstname"
                aria-label="Inject Recipient Variable i.e. Firstname"
              >
                <i className="bi bi-person-bounding-box"></i>
              </span>
            </button>
            <ul className="dropdown-menu scrollable-menu">
              <li>
                <a className="dropdown-item" href="#" onClick={injectVariable}>
                  Firstname_FirstCap
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-2">
          <textarea
            className="SMS_Msg form-control"
            rows="5"
            placeholder="Message"
            name="message"
            ref={mainMessageRef}
            value={option.message ?? ""}
            onChange={e => updateData(e, index)}
          ></textarea>
        </div>
        <div className="row">
          <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
            <a href="#" onClick={doNothing}>
              <i
                data-bs-toggle="tooltip"
                title=""
                className="bi bi-link-45deg"
                data-bs-original-title="Add URL"
                aria-label="Add URL"
              ></i>
            </a>
            <a
              href="#"
              onClick={doNothing}
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Insert Image/Video"
              aria-label="Insert Image/Video"
            >
              <i className="bi bi-camera-fill"></i>
            </a>
            <a
              href="#"
              onClick={doNothing}
              className="chatNoteImg"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Upload file"
              aria-label="Upload file"
            >
              <i className="bi bi-paperclip"></i>
            </a>
            <a
              href="#"
              onClick={doNothing}
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Inject Emoji"
              aria-label="Inject Emoji"
            >
              <i className="bi bi-emoji-smile"></i>
            </a>
            <input
              type="file"
              accept="image/*"
              className="chatNoteImgFile"
              style={{
                visibility: "hidden",
                position: "absolute",
                width: "1px",
              }}
            />
            <span className="Msg_counter">
              <span>{140 - (option.message?.length ?? 0)}</span> remaining
            </span>
          </div>
          <div className="col-lg-6 text-end">
            <button type="button" className="btn btn-primary">
              <i className="bi bi-send-fill"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmsOption
