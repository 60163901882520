import React from "react"
import InitialRow from "./initialRow"
import DhAutoRow from "../../../Item/dhAuto/dhAutoRow"

const DhAutoPanel = ({ states, setStates }) => {
  const addDhaRow = e => {
    setStates({
      ...states,
      dhAuto: {
        ...states.dhAuto,
        automation_option: [
          ...states.dhAuto.automation_option,
          {
            automationOptionType: "emailMe",
          },
        ],
      },
    })
  }

  return (
    <div className={`col-lg-12 mb-3${states.showDhAutoPanel ? "" : " d-none"}`} id="for_you_panel">
      <div className="accordion" id="foryouPanelAccordion">
        <div className="accordion-item panel panel-blue">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#for_you_container"
              aria-expanded="true"
              aria-controls="for_you_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-gear-fill"></i>
              </span>
              <span className="panel-heading-title">FOR YOU: Notification</span>
            </div>
          </div>
          <div
            id="for_you_container"
            className="accordion-collapse collapse show"
            aria-labelledby="for_you_container"
          >
            <div className="accordion-body">
              <div className="forYouOptionContainer">
                <InitialRow states={states} setStates={setStates} />
                {states.dhAuto?.automation_option?.map((data, index) => {
                  return (
                    <DhAutoRow key={index} states={states} setStates={setStates} index={index} data={data} />
                  )
                })}
                <div className="text-center btnAddNewFYOptionContainer">
                  <button
                    className="btnAddNewGreenDashed btnAddNewFYOption"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Add More to Data &amp; Automations"
                    aria-label="Add More to Data &amp; Automations"
                    onClick={addDhaRow}
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DhAutoPanel
