import React from "react"
import Tags from "../../Doc/MoreOptions/tags"

const MoreOptions = ({ states, setStates }) => {
  const setTags = tags => {
    setStates({ ...states, moreOptions: { ...states.moreOptions, tags } })
  }

  const updateData = e => {
    setStates({ ...states, moreOptions: { ...states.moreOptions, [e.target.name]: e.target.value } })
  }

  return (
    <div className={`col-lg-12 mb-3${states.showMoreOptionsPanel ? "" : " d-none"}`} id="more_panel">
      <div className="accordion" id="morePanelAccordion">
        <div className="accordion-item panel panel-grey">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#more_container"
              aria-expanded="true"
              aria-controls="more_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-three-dots"></i>
              </span>
              <span className="panel-heading-title">More Options</span>
            </div>
          </div>
          <div
            id="more_container"
            className="accordion-collapse collapse show"
            aria-labelledby="more_container"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-tags-fill"></i> Tags
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <Tags
                          tags={states.moreOptions?.tags || []}
                          setTags={setTags}
                          tempTags={states.moreOptions?.tags || []}
                          setTempTags={setTags}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-globe"></i> Language
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <select
                          name="language"
                          id="Language"
                          className="form-select"
                          value={states.moreOptions?.language || "default"}
                          onChange={updateData}
                        >
                          <option value="default">Default Language</option>
                          {states.languages?.map(language => {
                            return (
                              <option value={language.code} key={language.id}>
                                {language.language}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreOptions
