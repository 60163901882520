import React from "react"

const CurrencyIcon = ({ currencySymbol }) => {
  const symbolTextArray = { $: "dollar", "£": "pound", "€": "euro", "₹": "rupee", "¥": "yen", "₿": "bitcoin" }

  return (
    <>
      {["$", "£", "€", "₹", "¥", "₿"].includes(currencySymbol) && (
        <i className={`bi bi-currency-${symbolTextArray[currencySymbol]}`}></i>
      )}
      {!["$", "£", "€", "₹", "¥", "₿"].includes(currencySymbol) && <span>{currencySymbol}</span>}{" "}
    </>
  )
}

export default CurrencyIcon
