import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Detail = ({ states, setStates }) => {
  const [membershipRequired, setMembershipRequired] = useState("notRequired")
  const [selectList, setSelectList] = useState(["selectDoc0"])

  const updateData = e => {
    setStates({ ...states, [e.target.name]: e.target.value })
  }

  const updateNestedField = (e, parentField) => {
    setStates({ ...states, [parentField]: { ...states[parentField], [e.target.name]: e.target.value } })
  }

  useEffect(() => {
    setMembershipRequired(states.membershipJson.length === 0 ? "notRequired" : "required")
  }, [states.isDataLoaded])

  const addMoreSelect = e => {
    const next = `selectDoc${parseInt(selectList[selectList.length - 1].replace("selectDoc", "")) + 1}`
    setSelectList([...selectList, next])
  }

  const removeSelect = id => {
    setSelectList(selectList.filter(text => text !== id))
  }
  return (
    <div className="col-lg-12 mb-3" id="paywall_rules_panel">
      <div className="accordion" id="paywallPanelAccordion">
        <div className="accordion-item panel panel-white">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#paywall_rules_container"
              aria-expanded="true"
              aria-controls="paywall_rules_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-calendar"></i>
              </span>
              <span className="panel-heading-title">Service Details</span>
            </div>
          </div>
          <div
            id="paywall_rules_container"
            className="accordion-collapse collapse show"
            aria-labelledby="paywall_rules_container"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto">
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label">Description</label>
                    <div className="col-sm-8">
                      <textarea
                        id="discriptionMessagebox"
                        name="description"
                        className="form-control"
                        rows="5"
                        value={states.description ?? ""}
                        onChange={updateData}
                      ></textarea>
                      <span id="discription_msg_counter">
                        <span>{1024 - (states.description?.length ?? 0)}</span> Remaining
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label">Time Required</label>
                    <div className="col-sm-8">
                      <div className="row row-cols-sm-auto g-2 align-items-center">
                        <div className="col-12">
                          <select
                            name="timeFlexibility"
                            id="timeFlexibility"
                            className="form-select"
                            value={states.timeRequiredJson?.timeFlexibility ?? "Fixed"}
                            onChange={e => updateNestedField(e, "timeRequiredJson")}
                          >
                            <option value="Fixed">Fixed</option>
                            <option value="Flexible">Flexible</option>
                          </select>
                        </div>
                        <div className="col-12">Duration </div>
                        <div className="col-12">
                          <input
                            type="number"
                            name="timeReq"
                            id="timeReq"
                            value={states.timeRequiredJson?.timeReq ?? 30}
                            onChange={e => updateNestedField(e, "timeRequiredJson")}
                            className="form-control mr-0 mr-lg-2 mb-2 mb-lg-0"
                          />
                        </div>
                        <div className="col-12">
                          <select
                            name="timeFreq"
                            className="form-select"
                            value={states.timeRequiredJson?.timeFreq ?? "minutes"}
                            onChange={e => updateNestedField(e, "timeRequiredJson")}
                          >
                            <option value="minutes">Minutes</option>
                            <option value="hours">Hours</option>
                            <option value="days">Days</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label">Visibility</label>
                    <div className="col-sm-8">
                      <select
                        name="visibility"
                        id="serviceVisibility"
                        className="form-select "
                        value={states.visibility ?? "public"}
                        onChange={updateData}
                      >
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="hidden">Hidden</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label">Location / Venue / Method</label>
                    <div className="col-sm-8">
                      <select
                        name="locationOpt"
                        className="form-select locationOpts"
                        value={states.locationJson?.locationOpt ?? "office"}
                        onChange={e => updateNestedField(e, "locationJson")}
                      >
                        <option value="">== Select ==</option>
                        <option value="phone">Phone</option>
                        <option value="webinar">Webinar</option>
                        <option value="office">Office</option>
                        <option value="yourHome">Your Home</option>
                        <option value="fixedLocation">Other (Fixed Location)</option>
                        <option value="usersLocation">Other (User’s Location)</option>
                      </select>
                    </div>
                  </div>

                  {states.locationJson?.locationOpt === "phone" && (
                    <div className="mb-3 row locationOptRow locationOptPhoneRow">
                      <label className="col-sm-4 col-form-label"></label>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <span className="input-group-text">Phone # to Call</span>
                          <input
                            type="text"
                            name="phoneToCall"
                            placeholder="Phone Number"
                            value={states.locationJson?.phoneToCall ?? ""}
                            onChange={e => updateNestedField(e, "locationJson")}
                            className="form-control "
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {states.locationJson?.locationOpt === "webinar" && (
                    <div className="mb-3 row locationOptRow locationOptWebinarRow">
                      <label className="col-sm-4 col-form-label"></label>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <span className="input-group-text">URL to Join</span>
                          <input
                            type="url"
                            name="urlToJoin"
                            value={states.locationJson?.urlToJoin ?? "https://"}
                            onChange={e => updateNestedField(e, "locationJson")}
                            placeholder="https://"
                            className="form-control "
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {states.locationJson?.locationOpt === "fixedLocation" && (
                    <React.Fragment>
                      <div className="mb-3 row locationOptRow locationOptFixedLocationRow">
                        <label className="col-sm-4 col-form-label"></label>
                        <div className="col-sm-8">
                          <div className="input-group">
                            <span className="input-group-text">Name</span>
                            <input
                              type="text"
                              name="fixedLocationName"
                              placeholder="Fixed Location Name"
                              value={states.locationJson?.fixedLocationName ?? ""}
                              onChange={e => updateNestedField(e, "locationJson")}
                              className="form-control "
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 row locationOptRow locationOptFixedLocationRow">
                        <label className="col-sm-4 col-form-label"></label>
                        <div className="col-sm-8">
                          <div className="input-group">
                            <span className="input-group-text">Full Address</span>
                            <input
                              type="text"
                              name="serviceFullAddress"
                              placeholder="Full Address"
                              value={states.locationJson?.serviceFullAddress ?? ""}
                              onChange={e => updateNestedField(e, "locationJson")}
                              className="form-control "
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {states.locationJson?.locationOpt === "usersLocation" && (
                    <div className="mb-3 row locationOptRow locationOptUsersLocationRow">
                      <label className="col-sm-4 col-form-label"></label>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <span className="input-group-text">Name</span>
                          <input
                            type="text"
                            name="userLocationName"
                            placeholder="User Location Name"
                            value={states.locationJson?.userLocationName ?? ""}
                            onChange={e => updateNestedField(e, "locationJson")}
                            className="form-control "
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label">
                      Meeting Type{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            Use unticketed for sales meetings, asset rental, etc; Ticketed is for a # of seats
                            or slots available, i.e. whitewater rafting per-seat
                          </Tooltip>
                        }
                      >
                        <span
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Use unticketed for sales meetings, asset rental, etc; Ticketed is for a # of seats or slots available, i.e. whitewater rafting per-seat"
                          aria-label="Use unticketed for sales meetings, asset rental, etc; Ticketed is for a # of seats or slots available, i.e. whitewater rafting per-seat"
                        >
                          <i className="bi bi-question-circle-fill"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <div className="col-sm-8">
                      <select
                        name="ticketed"
                        className="form-select ticketedOpts "
                        value={states.ticketedJson?.ticketed ?? "no"}
                        onChange={e => updateNestedField(e, "ticketedJson")}
                      >
                        <option value="no">Not Ticketed</option>
                        <option value="yes">Ticketed</option>
                      </select>
                    </div>
                  </div>

                  {states.ticketedJson?.ticketed === "yes" && (
                    <div className="mb-3 row ticketedRow">
                      <label className="col-sm-4 col-form-label"></label>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <span className="input-group-text">Max Participants</span>
                          <input
                            type="number"
                            name="maxParticipants"
                            className="form-control "
                            value={states.ticketedJson?.maxParticipants ?? 0}
                            onChange={e => updateNestedField(e, "ticketedJson")}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label">Membership / User Type is</label>
                    <div className="col-sm-8">
                      <select
                        name="membershipReq"
                        className="form-select membershipOpts "
                        value={membershipRequired}
                        onChange={e => setMembershipRequired(e.target.value)}
                      >
                        <option value="notRequired">Not Required</option>
                        <option value="required">Required</option>
                      </select>
                    </div>
                  </div>

                  {membershipRequired === "required" && (
                    <div className="mb-3 row membershipRequiredRow">
                      <label className="col-sm-4 col-form-label"></label>
                      <div className="col-sm-8">
                        <p className="mb-1">User must have an active agreement / subscription with any of</p>

                        {selectList.map((selectDoc, index) => {
                          return (
                            <div className="input-group mb-2" key={index}>
                              <select className="form-select" id={selectDoc}>
                                <option>== List of docs ==</option>
                              </select>

                              {selectDoc === selectList[selectList.length - 1] && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Add Active Agreement</Tooltip>}
                                >
                                  <button
                                    className="btn btnAddNewGreenDashed btnAddNewAgreement"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    data-bs-original-title="Add Active Agreement"
                                    aria-label="Add Active Agreement"
                                    onClick={addMoreSelect}
                                  >
                                    <i className="bi bi-plus-lg"></i>
                                  </button>
                                </OverlayTrigger>
                              )}

                              {selectDoc !== selectList[selectList.length - 1] && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                  <button
                                    className="btn btn-danger btnRemoveAgreement"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-title="Delete"
                                    data-bs-original-title=""
                                    onClick={e => removeSelect(selectDoc)}
                                    title=""
                                  >
                                    <i className="bi bi-x-lg"></i>
                                  </button>
                                </OverlayTrigger>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
