import React, { useRef, useState, useEffect } from "react"

const EmailOption = ({ states, setStates, index, updateData }) => {
  const option = states.uxFlow.additional_options[index]
  const messageMainRef = useRef(null)
  const subjectRef = useRef(null)
  const [focused, setFocused] = useState(messageMainRef)

  const doNothing = e => {
    e.preventDefault()
  }

  const injectVariable = e => {
    e.preventDefault()
    const start = focused.current.selectionStart
    const end = focused.current.selectionEnd
    const currentValue = focused.current.value
    const newText = `${currentValue.slice(0, start)}[${e.target.innerText}]${currentValue.slice(end)}`
    const newPosition = start + `[${e.target.innerText}]`.length
    focused.current.setSelectionRange(newPosition, newPosition)
    updateOptionData(e, focused.current.name, newText)
  }

  const updateOptionData = (e, fieldName, value) => {
    e.preventDefault()

    const automation_option = states.uxFlow.automation_option.map((option, i) => {
      if (i !== index) {
        return option
      }

      return { ...option, [fieldName]: value }
    })
    setStates({ ...states, uxFlow: { ...states.uxFlow, automation_option } })
  }

  const addCc = e => {
    e.preventDefault()
  }

  const addBcc = e => {
    e.preventDefault()
  }

  return (
    <div className="col-lg-8 col-md-10 mx-auto mt-3 forThemOptionField FT_getAnEmail">
      <div className="colHeading">
        <strong>
          <i className="bi bi-envelope-fill"></i> Autoresponder
        </strong>
      </div>
      <div className="dh-block-body">
        <div className="mb-2 ChatDockEmailToOpt w-100">
          <div className="row align-items-center">
            <div className="col-sm-3">
              <div className="d-inline-block">
                <button
                  className="btn btnAddNewGreenDashed me-1"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span data-bs-toggle="tooltip" title="" data-bs-original-title="AImo" aria-label="AImo">
                    <i className="bi bi-robot"></i>
                  </span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <strong className="dropdown-item-text">== Email ==</strong>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      AIDA - Attn, Interest, Desire, Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Pain, Agitate, Solve
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Cold Email
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Email Newsletter
                    </a>
                  </li>
                  <li>
                    <strong className="dropdown-item-text">== ARTICLES &amp; BLOG ==</strong>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Article Writer Collab Style
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Article Writer Title Dependent
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Article Rewriter (English)
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Article Outliner
                    </a>
                  </li>
                  <li>
                    <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Twitter Tweets
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      LinkedIn Posts
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Instagram Captions
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      YouTube Helper
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      TikTok Helper
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-inline-block">
                <button
                  className="btn btnAddNewGreenDashed me-1"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Templates"
                    aria-label="Templates"
                  >
                    <i className="bi bi-journals"></i>
                  </span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Doc 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Doc 2
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-inline-block me-1">
                <button
                  className="btn btnAddNewGreenDashed"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Snippets"
                    aria-label="Snippets"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Snippets 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={doNothing}>
                      Snippets 2
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-inline-block">
                <button
                  className="btn btnAddNewGreenDashed"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Inject Recipient Variable i.e. Firstname"
                    aria-label="Inject Recipient Variable i.e. Firstname"
                  >
                    <i className="bi bi-person-bounding-box"></i>
                  </span>
                </button>
                <ul className="dropdown-menu scrollable-menu">
                  <li>
                    <a className="dropdown-item" href="#" onClick={injectVariable}>
                      Firstname_FirstCap
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-9">
              <div className="row gx-1 justify-content-end align-items-center">
                <div className="col-auto">via</div>
                <div className="col-auto">
                  <select
                    name="ChatDockEmailUsing"
                    id="ChatDockEmailUsing"
                    className="form-select invisibleField"
                    value={option.emailFrom ?? ""}
                    onChange={e => updateOptionData(e, "emailFrom", e.target.value)}
                  >
                    {states.primaryEmails?.map((email, index) => {
                      return (
                        <option key={`user_email_i_${index}`} value={email.value}>
                          {email.value}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-auto ChatDockEmailAs">
                  <div className="d-inline-block">
                    <button
                      className="btn btn-default"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Default"
                        aria-label="Default"
                      >
                        <i className="bi bi-envelope-fill"></i>
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={e => updateOptionData(e, "emailFormat", "Default")}
                        >
                          <i className="bi bi-envelope-fill"></i> Default
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={e => updateOptionData(e, "emailFormat", "Secure message")}
                        >
                          <i className="bi bi-lock-fill"></i> Secure Message (Login Required)
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={e => updateOptionData(e, "emailFormat", "Branded Newsletter")}
                        >
                          <i className="bi bi-code-slash"></i> Branded Newsletter
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={e => updateOptionData(e, "emailFormat", "Plain")}
                        >
                          <i className="bi bi-list"></i> Plain Text
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto ChatDockEmailCcBcc">
                  <div className="d-inline-block">
                    <button
                      className="btn btn-default"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Add Cc/Bcc"
                        aria-label="Add Cc/Bcc"
                      >
                        <i className="bi bi-plus-lg"></i>
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#" onClick={addCc}>
                          <i className="bi bi-envelope-fill"></i> Add Cc
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={addBcc}>
                          <i className="bi bi-envelope-fill"></i> Add Bcc
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto ChatDockEmailCcBccInput position-relative d-none">
                  <a href="#" className="btnRemoveChatDockEmailCcBccInput">
                    <i className="bi bi-x-lg"></i>
                  </a>
                  <input type="email" className="form-control" placeholder="Enter email address" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Subject"
            name="subject"
            ref={subjectRef}
            value={option.subject}
            onFocus={e => setFocused(subjectRef)}
            onChange={e => updateOptionData(e, "subject", e.target.value)}
          />
        </div>
        <div className="mb-2">
          <textarea
            className="form-control ChatDockEmailBox"
            placeholder="Message"
            rows="5"
            value={option.messageMain}
            onChange={e => updateOptionData(e, "messageMain", e.target.value)}
            ref={messageMainRef}
            name="messageMain"
            onFocus={e => setFocused(messageMainRef)}
          ></textarea>
        </div>
        <div className="text-end">
          <div className="row">
            <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
              <a href="#chatDockURLInjector" data-bs-toggle="modal">
                <i
                  data-bs-toggle="tooltip"
                  title=""
                  className="bi bi-link-45deg"
                  data-bs-original-title="Add URL"
                  aria-label="Add URL"
                ></i>
              </a>
              <a
                href="#"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Insert Image/Video"
                aria-label="Insert Image/Video"
              >
                <i className="bi bi-camera-fill"></i>
              </a>
              <a
                href="#"
                className="chatNoteImg"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Upload file"
                aria-label="Upload file"
              >
                <i className="bi bi-paperclip"></i>
              </a>
              <a
                href="#"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Inject Emoji"
                aria-label="Inject Emoji"
              >
                <i className="bi bi-emoji-smile"></i>
              </a>
              <input
                type="file"
                accept="image/*"
                className="chatNoteImgFile"
                style={{
                  visibility: "hidden",
                  position: "absolute",
                  width: "1px",
                }}
              />
            </div>
            <div className="col-lg-6 text-end">
              <button type="button" className="btn btn-primary">
                <i className="bi bi-send-fill"></i> Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailOption
