import * as React from "react"
import { useEffect, useState } from "react"
import { createTagString } from "../../../../services/tagsHelper"
import "../../../../css/bootstrap-tagsinput.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const ContactTags = ({ tags, setTags, tempTags, setTempTags, index }) => {
  const [uiBlockClasses, setUiBlockClasses] = useState("CD_PrivateTag_Container"),
    [editBlockClasses, setEditBlockClasses] = useState("CD_PrivateTag_Editor position-relative d-none"),
    [plainTags, setPlainTags] = useState(createTagString(tags)),
    [newTag, setNewTag] = useState(""),
    [addTag, setAddTag] = useState(false),
    [updateTags, setUpdateTags] = useState(false),
    [restoreTags, setRestoreTags] = useState(false),
    [removeTagIndex, setRemoveTagIndex] = useState(null)

  const [assignBlock, setAssignBlock] = useState("")
  const [tagColor, setTagColor] = useState("")
  const [assignTag, setAssignTag] = useState([])
  const [assignTags, setAssignTags] = useState([])
  const [assignTagsScore, setAssignTagsScore] = useState(null)

  const handleAssignBlock = tagName => {
    setAssignBlock(tagName)
  }

  const showEditTagBlock = () => {
    setUiBlockClasses("CD_PrivateTag_Container d-none")
    setEditBlockClasses("CD_PrivateTag_Editor position-relative")
  }

  const hideEditTagBlock = () => {
    setUiBlockClasses("CD_PrivateTag_Container")
    setEditBlockClasses("CD_PrivateTag_Editor position-relative d-none")
  }

  const handleTagColor = (tagType, tag, tags) => {
    setAssignTags(tags)
    setAssignTag([tag])
    let type = tagType
    switch (type) {
      case "blue":
        setTagColor("blue")
        break
      case "pink":
        setTagColor("pink")
        break
      case "beige":
        setTagColor("beige")
        break
      case "green":
        setTagColor("green")
        break
      case "gray":
        setTagColor("gray")
        break
      default:
        setTagColor("blue")
    }
  }

  useEffect(() => {
    if (assignTag.length > 0) {
      assignTag[0].color = tagColor
    }
    assignTag.forEach(elements => {
      const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
      if (tagNames > -1) {
        assignTags[tagNames] = elements
      } else {
        assignTags = assignTags.push(elements)
      }
    })
    if (assignTag.length > 0) {
      // setAssignBlock("")
      setUpdateTags(true)
    }
  }, [tagColor])

  const changeTagScore = (e, tag, tags) => {
    setAssignTagsScore(e.target.value)
    setAssignTags(tags)
    setAssignTag([tag])
  }

  const handleTagScore = assignTagsScore => {
    if (assignTagsScore !== null) {
      if (assignTag.length > 0) {
        assignTag[0].score = assignTagsScore
      }
      assignTag.forEach(elements => {
        const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
        if (tagNames > -1) {
          assignTags[tagNames] = elements
        } else {
          assignTags = assignTags.push(elements)
        }
      })
      if (assignTag.length > 0) {
        // setAssignBlock("")
        setUpdateTags(true)
      }
    }
  }

  useEffect(() => {
    if (addTag) {
      setAddTag(false)
      let filterTag = tempTags.filter(tags => {
        return tags.tag === newTag
      })
      if (filterTag.length === 0) {
        setTempTags([
          ...tempTags,
          {
            tag: newTag,
            score: 0,
            color: "blue",
          },
        ])
        setNewTag("")
      }
    }

    if (removeTagIndex) {
      setRemoveTagIndex(null)
      let tempTag = []

      tempTags.map((tagArray, arrayIndex) => {
        if (parseInt(removeTagIndex) !== arrayIndex) {
          tempTag = [...tempTag, tagArray]
        }
        if (arrayIndex === tempTags.length - 1) {
          setTempTags([...tempTag])
        }
      })
    }

    if (updateTags) {
      setUpdateTags(false)
      setTags([...tempTags])
    }

    if (restoreTags) {
      setRestoreTags(false)
      setTempTags([...tags])
    }
  })

  return (
    <React.Fragment>
      <div className={uiBlockClasses}>
        <div className="SwiftCloudTableTags">
          <div className="SwiftCloudTableTags">
            <ul>
              {tempTags.length > 0 &&
                tempTags.map((tagDetail, tagIndex) => {
                  return (
                    <li
                      key={tagIndex}
                      className={assignBlock == tagDetail ? `tag-${tagColor}` : `tag-${tagDetail.color}`}
                      onMouseEnter={() => handleAssignBlock(tagDetail)}
                      onMouseLeave={() => setAssignBlock("")}
                    >
                      <a key={tagIndex} href="#" data-tagname="Designer">
                        {tagDetail.tag}{" "}
                        {/* {assignTagsScore === null ? `(${tagDetail.score})` : `(${assignTagsScore})`} */}
                        {tagDetail.score == 0 ? "" : ` (${tagDetail.score})`}
                      </a>
                      {assignBlock == tagDetail && (
                        <div className="SwiftCloudTagScore">
                          <div className="mb-2 SwiftCloudTagScoreType">
                            <a
                              className="SwiftCloudTagScoreBlue"
                              onClick={() => {
                                handleTagColor("blue", tagDetail, tempTags)
                              }}
                              data-type="blue"
                            />
                            <a
                              className="SwiftCloudTagScorePink"
                              onClick={() => {
                                handleTagColor("pink", tagDetail, tempTags)
                              }}
                              data-type="pink"
                            />
                            <a
                              className="SwiftCloudTagScoreBeige"
                              onClick={() => handleTagColor("beige", tagDetail, tempTags)}
                              data-type="beige"
                            />
                            <a
                              className="SwiftCloudTagScoreGreen"
                              onClick={() => handleTagColor("green", tagDetail, tempTags)}
                              data-type="green"
                            />
                            <a
                              className="SwiftCloudTagScoreGray"
                              onClick={() => handleTagColor("gray", tagDetail, tempTags)}
                              data-type="gray"
                            />
                          </div>
                          <span>Assign Tag Score / Value</span>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control tagScoreInput"
                              value={assignTagsScore === null ? tagDetail.score : assignTagsScore}
                              onChange={event => changeTagScore(event, tagDetail, tempTags)}
                              onKeyUp={event => {
                                if (event.key === "Enter") {
                                  handleTagScore(assignTagsScore, tagDetail, tags)
                                }
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-success btnSaveTagScore"
                              onClick={() => handleTagScore(assignTagsScore, tagDetail, tempTags)}
                              onMouseLeave={() => handleTagScore(assignTagsScore, tagDetail, tags)}
                            >
                              <i className="bi bi-check2"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </li>
                  )
                })}
            </ul>

            {tempTags.length === 0 && (
              <a
                onClick={showEditTagBlock}
                className="btn btnEditPrivateTags btnAddNewGreenDashed mt-2"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Add Tags"
              >
                <i className="bi bi-plus-lg" />
              </a>
            )}
            {tempTags.length > 0 && (
              <a
                onClick={showEditTagBlock}
                className="btn btnEditPrivateTags"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Edit Tags"
              >
                <i className="bi bi-pencil-square" />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className={`${editBlockClasses} d-flex`}>
        <div className="bootstrap-tagsinput">
          {tempTags.length > 0 &&
            tempTags.map((tagDetail, tagIndex) => (
              <span className="tag label label-info" key={tagDetail.tag}>
                {tagDetail.tag}
                <span data-role="remove" onClick={() => setRemoveTagIndex(`${tagIndex}`)} />
                <span data-role="drag" />
              </span>
            ))}
          <input
            type="text"
            placeholder="Enter a #tag then click Tab"
            name={`contact_tags_${index}`}
            size="27"
            onKeyDown={e => {
              if (e.code === "Tab" || e.code === "Enter") {
                if (tempTags.length === 0) {
                  setAddTag(false)
                  setTempTags([
                    ...tempTags,
                    {
                      tag: newTag,
                      score: 0,
                      color: "blue",
                    },
                  ])
                  setNewTag("")
                } else {
                  setAddTag(true)
                }
              }
            }}
            value={newTag}
            // defaultValue={newTag}
            onChange={e => {
              setNewTag(e.target.value)
            }}
          />
        </div>
        <OverlayTrigger placement="top" overlay={<Tooltip>Save</Tooltip>}>
          <button
            type="button"
            className="btn btnAddRoleTags btn-primary "
            data-bs-toggle="tooltip"
            title=""
            onClick={() => {
              hideEditTagBlock()
            }}
          >
            <i className="bi bi-check-lg" />
          </button>
        </OverlayTrigger>
        <input type="text" className="form-control d-none" defaultValue={plainTags} />
      </div>
    </React.Fragment>
  )
}

export default ContactTags
